.banner__cover {
  --margin-for-desktop: 0.625rem 3.75rem;
  --margin-for-tablet: 0.625rem 1.875rem;
  --margin-for-small: 0.625rem 0.9375rem;
  --margin-for-tv: 0 4.875rem 0;
  --showcase-margin: 0.625rem auto;
  --max-width-banner-showcase: 60rem;

  margin: var(--margin-for-small);

  @media (--mq-from-tablet) {
    margin: var(--margin-for-tablet);
  }

  @media (--mq-from-desktop) {
    margin: var(--margin-for-desktop);
  }

  @mixin tvDevice {
    margin: var(--margin-for-tv);
  }

  & > * {
    @mixin aspectRatioLegacy 16, 9, -1px;

    @media (--mq-from-tablet) {
      @mixin aspectRatioLegacy 1920, 495, -1px;
    }
  }

  &--showcase {
    width: 100%;
    max-width: var(--max-width-banner-showcase);
    margin: var(--showcase-margin);

    & > * {
      @mixin aspectRatioLegacy 16, 9, -1px;

      @media (--mq-from-tablet) {
        @mixin aspectRatioLegacy 960, 140, -1px;
      }
    }
  }
}

/* TV focus style */
:global {
  .banner .tv__focus::before {
    @mixin tv-focus-pseudo-border;
  }
}
